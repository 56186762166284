@import './variables';

._form_submit {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5px 16px;
	min-width: 176px;
	min-height: 40px;
	left: 0;
	top: 0;
	margin: 0 32px;
	background: $color-primary;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
	//border-radius: 48px;
}

._previous {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 9px 16px;
	min-width: 176px;
	min-height: 40px;
	left: 0;
	top: 0;
	margin: 0 32px;
	//background: $color-grey;
	//border-color: $color-grey;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
	//border-radius: 48px;

	&:hover {
		//background: $color-grey;
		//border-color: $color-grey;
	}
}

.remove_arrow {
	-webkit-appearance: none;
	margin: 0;
}

._btn_small {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0.9rem 3.6rem;
	min-width: 17.6rem;
	min-height: 4rem;
	left: 0;
	top: 0;
	box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.043);
	//border-radius: 4.8rem;
	flex: none;
	order: 1;
	flex-grow: 0;
}

._form_submit_small {
	@extend ._btn_small;

	//border-radius: 0;
	padding: 0.9rem 1.6rem;
	margin: 1.6rem 0;
	font-weight: bold;
	font-family: $rebranding-font-space-grotesk-sanseriff;
	// background: $color-primary;
	// font-family: $font-oxygen-sanseriff;
}

._previous_small {
	@extend ._btn_small;

	//background: $color-grey;
	//border-color: $color-grey;
	margin: 0 3.2rem 0 0;

	&:hover {
		//background: $color-grey;
		//border-color: $color-grey;
	}
}

.step_btn {
	min-width: 176px;
	min-height: 40px;
	//border-radius: 4.8rem;
	box-shadow: 0 2px 0 rgba($color-black, 0.043);
	color: $color-white;
	margin: 0 1rem;
}

._next_step {
	@extend .step_btn;

	background-color: $color-primary;
}

._previous_step {
	@extend .step_btn;

	background-color: $color-grey;
}

//new btn styles
.adminBtnStyle {
	background: $color-white;
	border: 1px solid $color-primary;
	box-sizing: border-box;
	border-radius: 48px;
	color: $color-primary;

	&_error {
		background: $color-white;
		border: 1px solid $color-pink-dark;
		box-sizing: border-box;
		border-radius: 48px;
		color: $color-pink-dark;
	}
}

.adminRepaymentEditBtnStyle {
	background: $color-white;
	border: 1px solid $color-grey-orange;
	box-sizing: border-box;
	border-radius: 48px;
	color: $color-grey-orange;
	margin-left: 8px;

	&_error {
		background: $color-white;
		border: 1px solid $color-grey-orange;
		box-sizing: border-box;
		border-radius: 48px;
		color: $color-grey-orange;
	}
}

.noColor {
	background-color: transparent !important;
	color: $rebranding-color-primary-dark !important;
	line-height: 22px;
}

._sp_Margin_left {
	margin-left: 16px;

	@media only screen and (max-width: 1287px) {
		margin-left: 0;
	}
}
