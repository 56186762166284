@import './variables.scss';

._middle {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

._start {
	text-align: start;
}

._full_width {
	width: 100%;
}

._middle_row {
	@extend ._middle;

	flex-direction: row;
}

._spin {
	font-size: 3.6rem;
}

._flex {
	display: flex;
}

._mobileFlexColumn {
	flex-direction: row;

	@media (max-width: $breakpoint-mobile) {
		flex-direction: column;
	}
}
