@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');
@import './utility/index.scss';

html {
	// This defines what 1rem is
	// Normally 1 rem = 16px (browser assumes 'font-size: 100%' means 1rem or 16px). We want to make 1rem = 10px to simplify our calculation.
	// If we make that then whenever We need to write 22px we can write 2.2rem, for 18px we can use 1.8rem.
	// 1 rem = 10px; 10px/16px = 62.5%
	font-size: 62.5% !important;
}

body {
	padding: 0;
	margin: 0;
	font-family: $rebranding-font-space-grotesk-sanseriff;

	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.hoverEffect:hover {
	transform: scale(1.1);
}

h1 {
	color: $color-grey;
	font-family: $rebranding-font-archivo-sanseriff;
	font-size: 5.6rem;
}

h2 {
	color: $color-grey;
	font-family: $rebranding-font-archivo-sanseriff;
	font-size: 4rem;
}

h3 {
	color: $rebranding-color-purple-dark;
	font-family: $rebranding-font-archivo-sanseriff;
	font-size: 2.8rem;
	line-height: 3.3rem;
	margin: 0;
}

h4 {
	color: $color-grey;
	font-family: $rebranding-font-archivo-sanseriff;
	font-size: 2rem;
}

p {
	color: $color-grey-light;
	font-size: 1.6rem;
	font-family: $rebranding-font-space-grotesk-sanseriff;
}

.ant-input-number-handler-wrap {
	visibility: hidden;
}

.hidden {
	display: none;
}

.draggerText {
	font-size: 16px;
}

.ant-btn-primary {
	background: $rebranding-color-primary-dark;
	border-color: $rebranding-color-primary-dark;
}

.ant-btn-primary:hover {
	background: $rebranding-color-primary-dark;
	border-color: $rebranding-color-primary-dark;
	opacity: 0.9;
}

.spaceFont {
	font-family: $rebranding-font-space-grotesk-sanseriff;
}

.ant-steps-item-process .ant-steps-item-icon {
	background-color: white;
	border-color: $rebranding-color-orange !important;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
	color: white !important;
}

/* prettier-ignore */
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
	background: $rebranding-color-orange !important;
}

/* prettier-ignore */
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
	background: $rebranding-color-orange !important;
}

.responsivehambugerIcon {
	@media (max-width: $breakpoint-mobile) {
		display: flex;
		justify-content: flex-end !important;
		align-items: center;
		padding: 1rem;
	}
}

.link-style {
	text-decoration: none !important;
}

.responsiveStepperHorizontal {
	display: none;

	@media (max-width: $breakpoint-mobile) {
		// display: flex;
		display: none;
	}
}

.responsiveStepperVertical {
	display: flex;

	@media (max-width: $breakpoint-mobile) {
		display: flex;
	}
}

.clientDashresponsivework {
	height: 630px;
	overflow: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	@media (max-width: $breakpoint-tablet) {
		height: 650px;
	}

	@media (max-width: $breakpoint-mobile) {
		height: auto;
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.clientDashresponsivework::-webkit-scrollbar {
	display: none;
}
